<template>
	<div id="UpLoadInfo">
		<div class="UpLoadInfoBox">
			<div class="box">
				<h1>创建账号</h1>
				<ul class="steps">
					<template v-for="(item,i) in steps">
						<li :key="i" v-if="item.active<3">
							<div class="icon" v-if="item.active==2">
								0{{i+1}}
							</div>
							<div :class="item.active == 1?`icon active`:`icon`" v-if="item.active==1">
								<i class="el-icon-check" style="font-size:18px;"></i>
							</div>
							{{item.title}}
						</li>
					</template>
				</ul>
				<div class="StepBox">Step 2/{{steps.length}}</div>
				<p class="StepTitle">上传身份信息(点击上传)</p>
				<el-form :model="form" :rules="rules" ref="form" label-position="top">
					<el-row>
						<el-col :span="12">
							<el-form-item label="身份证人像面：">
								<el-upload class="avatar-uploader" :action="`${baseUrl}/sign/live/doctor/uploadIdCard`"
									:headers="{token:token}" :show-file-list="false" :on-success="handleAvatarSuccess1"
									:before-upload="beforeAvatarUpload">
									<img v-if="imageUrl['1']" :src="imageUrl['1'].file" class="avatar">
									<img v-else class="el-icon-plus" src="../../../assets/imgs/idCard_rx.png" alt="">
								</el-upload>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="身份证国徽面：">
								<el-upload class="avatar-uploader" :action="`${baseUrl}/sign/live/doctor/uploadFile`"
									:show-file-list="false" :on-success="handleAvatarSuccess2"
									:before-upload="beforeAvatarUpload">
									<img v-if="imageUrl['2']" :src="imageUrl['2'].file" class="avatar">
									<img v-else class="el-icon-plus" src="../../../assets/imgs/idCard_gh.png" alt="">
								</el-upload>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="银行卡卡号面：">
								<el-upload class="avatar-uploader"
									:action="`${baseUrl}/sign/live/doctor/uploadBankCard`" :headers="{token:token}"
									:show-file-list="false" :on-success="handleAvatarSuccess3"
									:before-upload="beforeAvatarUpload">
									<img v-if="imageUrl['3']" :src="imageUrl['3'].file" class="avatar">
									<img v-else class="el-icon-plus" src="../../../assets/imgs/backCard.png" alt="">
								</el-upload>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="职业资格证照片：">
								<el-upload class="avatar-uploader" :action="`${baseUrl}/sign/live/doctor/uploadFile`"
									:show-file-list="false" :on-success="handleAvatarSuccess4"
									:before-upload="beforeAvatarUpload">
									<img v-if="imageUrl['4']" :src="imageUrl['4'].file" class="avatar">
									<img v-else class="el-icon-plus" src="../../../assets/imgs/zs.png" alt="">
								</el-upload>
							</el-form-item>
						</el-col>
					</el-row>

					<el-form-item>
						<div class="options">
							<!-- <el-button class="bank" @click="bank">返回</el-button> -->
							<el-button class="bank" @click="skip">跳过</el-button>
							<el-button class="next" @click="onSubmit">下一步</el-button>
						</div>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		information
	} from '@/request/api.js';
	import baseUrl from "@/request/config.js"
	export default {
		name: 'UpLoadInfo',
		data() {
			return {
				baseUrl: '',
				token: '',
				imageUrl: {
					'1': '',
					// '2': '',
					'3': '',
					'4': '',
				},
				checked: '',
				form: {
					name: '',
				},
				rules: {
					name: [{
						required: true,
						message: '请输入活动名称',
						trigger: 'blur'
					}],
				},
				steps: [{
					title: '创建账号',
					active: 1
				}, {
					title: '上传认证',
					active: 2
				}, {
					title: '完善信息',
					active: 3
				}, {
					title: '参与项目',
					active: 3
				}]
			}
		},
		created() {
			this.baseUrl = baseUrl;
			this.token = JSON.parse(localStorage.getItem('regToken')).token;
		},
		mounted() {},
		methods: {
			handleAvatarSuccess1(res, file) {
				console.log(JSON.parse(JSON.stringify(res)));
				if (res.data.cardId) {
					this.imageUrl['1'] = {
						file: URL.createObjectURL(file.raw),
						path: res.data.fileName
					}
				} else {
					this.$alert('身份证识别失败，请更换清晰的图片', '提示', {
						confirmButtonText: '确定',
						closeOnClickModal: true,
						type: "error",
						center: true
					});
				}
			},
			handleAvatarSuccess2(res, file) {
				this.imageUrl["2"] = {
					file: URL.createObjectURL(file.raw),
					path: res
				};
				this.$forceUpdate();
			},
			handleAvatarSuccess3(res, file) {
				console.log(JSON.parse(JSON.stringify(res)));
				if (res.data.bankNumber) {
					this.imageUrl['3'] = {
						file: URL.createObjectURL(file.raw),
						path: res.data.fileName
					}
				} else {
					this.$alert('银行卡识别失败，请更换清晰的图片', '提示', {
						confirmButtonText: '确定',
						closeOnClickModal: true,
						type: "error",
						center: true
					});
				}
			},
			handleAvatarSuccess4(res, file) {
				this.imageUrl['4'] = {
					file: URL.createObjectURL(file.raw),
					path: res
				};
				this.$forceUpdate();
			},
			beforeAvatarUpload(file) {
				// const isJPG = file.type === 'image/jpeg';
				const isLt20M = file.size / 10204 / 10204 < 20;
				// if (!isJPG) {
				// 	this.$message.error('上传头像图片只能是 JPG 格式!');
				// }
				if (!isLt20M) {
					this.$message.error('上传头像图片大小不能超过 20MB!');
				}
				return isLt20M;
			},
			agreement() {
				console.log(1)
			},
			bank() {
				this.$router.go(-1)
			},
			skip() {
				this.$router.push({
					name: "perInfo",
					query: this.$route.query
				})
			},
			onSubmit() {
				if (!this.imageUrl['1']) {
					this.$notify({
						title: '提示',
						type: 'warning',
						message: '请上传身份证人像面照片',
						position: 'bottom-right'
					});
				}
				//  else if (!this.imageUrl['2']) {
				// 	this.$notify({
				// 		title: '提示',
				// 		type: 'warning',
				// 		message: '请上传身份证国徽面照片',
				// 		position: 'bottom-right'
				// 	});
				// }
				else if (!this.imageUrl['3']) {
					this.$notify({
						title: '提示',
						type: 'warning',
						message: '请上传银行卡照片',
						position: 'bottom-right'
					});
				} else if (!this.imageUrl['4']) {
					this.$notify({
						title: '提示',
						type: 'warning',
						message: '请上传职业资格证照片',
						position: 'bottom-right'
					});
				} else {
					let paths = [];
					for (let i in this.imageUrl) {
						let obj = {
							"fileName": this.imageUrl[i].path,
							fileType: ''
						}
						if (i == '1') {
							obj.fileType = 'idCard'
						} else if (i == '2') {
							obj.fileType = 'idCardBack'
						} else if (i == '3') {
							obj.fileType = 'bankCard'
						} else if (i == '4') {
							obj.fileType = 'certificate'
						};
						paths.push(obj);
					};

					// console.log(JSON.parse(JSON.stringify(paths)))
					let data = {
						uploadFiles: JSON.stringify(paths)
					};
					information(data).then(res => {
						if (res.code == 200) {
							this.$notify({
								title: '提示',
								type: 'success',
								message: '上传成功',
								position: 'bottom-right'
							});
							this.$router.push({
								name: "perInfo",
								query: this.$route.query
							})
						} else {
							this.$notify({
								title: '提示',
								type: 'warning',
								message: res.msg,
								position: 'bottom-right'
							});
						}
					})
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	#UpLoadInfo {
		padding: 20px;
		height: 100%;
		background: rgba(244, 249, 253, 1);

		.UpLoadInfoBox {
			width: 600px;
			height: 100%;
			max-width: 1200px;
			display: flex;
			overflow: hidden;
			border-radius: 20px;
			flex-direction: row;
			box-shadow: 0 0 20px rgba(0, 0, 0, .1);

			.banner,
			.box {
				width: 600px;
				background-color: #fff;
			}

			.banner {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.logo {
					flex: 1;
					width: 100%;
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					padding: 40px 0 0 40px;

					img {
						height: 80px;
					}
				}

				.back {
					width: 400px;
					margin-bottom: 100px;
				}
			}

			.box {
				display: flex;
				align-items: center;
				flex-direction: column;
				padding: 50px;
				overflow: hidden;
				overflow-y: auto;

				h1 {
					font-size: 20px;
					margin-bottom: 30px;
					color: #112950;
				}

				.steps {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: flex-start;
					width: 100%;
					margin-bottom: 20px;

					li {
						display: flex;
						color: #112950;
						font-size: 16px;
						align-items: center;
						margin-right: 16px;

						.icon {
							width: 28px;
							height: 28px;
							background: #007AFF;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: 5px;
							border-radius: 5px;
							color: #fff;
							font-size: 14px;
						}

						.active {
							background: #ECF3FE;
							color: #007AFF;
						}
					}
				}

				.StepBox {
					width: 100%;
					font-size: 13px;
					color: #112950;
					margin-bottom: 20px;
				}

				.StepTitle {
					width: 100%;
					font-size: 22px;
					color: #112950;
					border-bottom: 1px solid #E5E5E5;
					padding-bottom: 10px;
					margin-bottom: 20px;
				}

				::v-deep .el-form {
					width: 100%;

					.el-form-item__label {
						padding: 0;
						line-height: 1;
						color: #112950;
					}

					.el-upload {
						height: 160px;
						overflow: hidden;

						img {
							width: 240px;
							padding: 10px;
							height: 160px;
							border-radius: 10px;
							overflow: hidden;
							cursor: pointer;
						}

						.el-upload--text {}
					}

					.options {
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;

						.el-button {
							width: 300px;
							height: 50px;
							background-color: #007AFF;
							color: #fff;
						}

						.next {
							background-color: #007AFF;
							color: #fff;
						}

						.bank {
							background-color: #fff;
							color: #FF5733;
							border: 1px solid #FF5733;
						}
					}
				}
			}
		}
	}
</style>